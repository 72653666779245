'use client'

import Section, { SectionProps } from '@lyra/core/components/Section'
import React from 'react'
import { YStack } from 'tamagui'

type Props = SectionProps

export default function LoadingPageHelper({ ...sectionProps }: Props) {
  return (
    <YStack flexGrow={1} flexShrink={1} alignItems="center" justifyContent="center" padding="$6">
      <Section noTopBorder {...sectionProps}>
        <Section.Spinner />
      </Section>
    </YStack>
  )
}
